import { Route, Routes } from "react-router-dom";
import Chat from "./components/chat/Chat";
import Home from "./components/Home";
import Review from "./components/review/Review";
import KeyAuth from "./components/keyauth/KeyAuth";
import Used from "./components/used/Used";
import NoLanguage from "./components/no_language/NoLanguage";
import MultiExperiences from "./components/multi_experiences/MultiExperiences";
import ProtectedRoutes from "./ProtectedRoutes";

const Views = () => {
    return (
        <Routes>
            <Route path="/" element={<KeyAuth title='Key Page' />} />
            <Route element={<ProtectedRoutes />}>
                <Route path="/home" element={<Home />} />
                <Route path="/multi-experiences" element={<MultiExperiences />} />
                <Route path="/used" element={<Used />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/review" element={<Review />} />                
                <Route path="/no-language" element={<NoLanguage />} />                
            </Route>
        </Routes>
    );
};
export default Views;