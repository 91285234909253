import DoneButton from "./buttons/DoneButton";
import ChoiceButton from "./buttons/ChoiceButton";
import NextButton from "./buttons/NextButton";
import InputText from "./input_texts/InputText";
import EndButton from "./buttons/EndButton";

const InputHub = (props) => {
    let hasInsupt = props.data.length > 0 && (props.data[props.data.length - 1].is_request || props.data[props.data.length - 1].is_error);

    if (hasInsupt) {
        if (props.data[props.data.length - 1].is_yes_no) {
            return (
                <DoneButton myData={props.data[props.data.length - 1]} onClick={props.onClickButton} />
            )
        } else if (props.data[props.data.length - 1].is_choice) {
            return (
                <ChoiceButton myData={props.data[props.data.length - 1]} onClick={props.onClickButton} />
            )
        } else {
            return (
                <InputText onClick={props.onClickButton} setResponseUser={props.setResponseUser}/>
            )
        }
    } else if(props.data.length > 0 && props.data[props.data.length - 1].is_last){
        return (
            <EndButton onClick={props.onClickButton} />
        )
    } else {
        return (
            <NextButton onClick={props.onClickButton} />
        )
    }
}

export default InputHub