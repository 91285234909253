import React, { useContext, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import axios from '../api/axios';

import i18n from '../translation/i18n';

const MultiExperiences = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { experiences } = location.state;
    const [token, setToken] = useState();
    const userRef = useRef();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('BelPaeseExpressToken');
        if (token) {
            setToken(token);
        }
    }, []);

    const handleExperience = async (experience) => {
        localStorage.setItem("BelPaeseExpressExperience_id", experience.experience_id);
        localStorage.setItem("BelPaeseExpressExperience_name", experience.experience_name);
        localStorage.setItem("BelPaeseExpressExperience_description", experience.experience_description);
        localStorage.setItem("BelPaeseExpressExperience_map", experience.experience_map);

        try {
            return navigate('/home', { state: { name: experience.experience_name, description: experience.experience_description } });
        } catch (err) {
            setErrMsg(t('ErrorChoiceExperience'));
            errRef.current.focus();
        }
    }

    return (
        <section>
            <div style={{ margin: 'auto', textAlign: 'center', fontFamily: 'Merriweather', boxShadow: "0px 0px 0px rgb(0 0 0 / 0.2)", width: "90%", padding: "10px", backgroundColor: "#7C112B", marginTop: "15%" }}>
            <h2 style={{ backgroundColor: "rgb(124 17 43 / 100%)", marginTop: "0px", fontSize: "20px", color: "#fcb900" }}>
                {t('MultiExperienceTitle')}
            </h2>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                    {experiences.map((experience, index) => (
                        <li key={index}>
                            <button style={{
                                    padding: "15px", paddingRight: "15px", paddingLeft: "15px", borderRadius: "0px", border: "2px solid #FDB933",
                                    backgroundColor: "white", color: "black", fontFamily: "Merriweather", fontSize: '15px', width: '280px', marginTop: '20px'
                                }} onClick={() => handleExperience(experience)}>
                                <strong>{experience.experience_name}</strong>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default MultiExperiences;
