import SweetAlert from 'sweetalert2';

export const showAlert = (t) => {

  SweetAlert.fire({
    title: t('AlertTitle'),
    html: t('AlertText') + '<br><br>' + t('AlertSecondText') + localStorage.getItem('BelPaeseExpressKey'),
    confirmButtonText: t('AlertButton'),
    confirmButtonColor: 'rgb(253, 185, 51)',
  });
};
