
import React from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";

const Used = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <section>
            <div style={{ textAlign: "center", fontFamily: "Merriweather" }}>
                <br />
                <h2 style={{ color: "rgb(253, 185, 51)" }}>{t('TitleTransfere')}</h2>
                <p style={{ color: "white" }}>{t('NoLanguageText')} <br />
                </p>
                <div style={{ height: "95px" }}></div>
                <button style={{
                    marginTop: "15px", padding: "10px", width: "30%", fontSize: "22px", height: "58px", display: "inline-block", color: "black",
                    backgroundColor: "white", border: "1px solid #FDB933", borderRadius: "5px", paddingBottom: "25px", fontFamily: "Merriweather",
                }}
                    onClick={() => navigate('/')}>
                    <p style={{ fontWeight: "bold", fontSize: "20px", marginTop: "5px" }}>{t('NoLanguageButton')}</p>
                </button>
            </div>
        </section>
    )
}

export default Used;
