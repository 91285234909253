
import React from "react";
import { useTranslation} from 'react-i18next';
import UsedButtons from "./UsedButtons";

const Used = () => {

    const {t} = useTranslation();

    return (
        <section>            
            <div style={{ textAlign: "center", fontFamily: "Merriweather" }}>
                <br />
                <h2 style={{ color: "rgb(253, 185, 51)" }}>{t('TitleTransfere')}</h2>
                <p style={{ color: "white" }}>{t('TextTransfere')} <br /><br />
                    {t('TextTransfere2')}
                </p>
                <div style={{ height: "95px" }}></div>
                <UsedButtons />
            </div>
        </section>
    )
}

export default Used;
