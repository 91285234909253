import SweetAlert from 'sweetalert2';

export const showAlertMap = (t) => {
    SweetAlert.fire({
        title: t('MapTitle'),
        html: `<img src="${localStorage.getItem('BelPaeseExpressExperience_map')}" style="width: 100%" />`,
        confirmButtonText: t('AlertButton'),
        confirmButtonColor: 'rgb(253, 185, 51)'
    });
};
