import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faStop } from '@fortawesome/free-solid-svg-icons'

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);    

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const AudioMessage = ({ audio }) => {

  const [playing, toggle] = useAudio(audio);

  return (
    <div>
      <button style={{ background: "rgba(0,0,0,0)", border: "1px solid black", borderRadius: "25px", height: "40px", width: "40px" }} onClick={toggle}>{playing ? <FontAwesomeIcon style={{ color: "black", fontSize: "20px" }} icon={faStop} /> : <FontAwesomeIcon style={{ color: "black", fontSize: "20px" }} icon={faPlay} />}</button>
    </div>
  );
};

export default AudioMessage;
