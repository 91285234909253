import SweetAlert from 'sweetalert2';

export const showAlertInfo = (t) => {
    SweetAlert.fire({
        title: t('AlertInfoTitle'),
        html: t('AlertInfoText'),
        confirmButtonText: t('AlertButton'),
        confirmButtonColor: 'rgb(253, 185, 51)'
    });
};
