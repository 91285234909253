import React from 'react'

const UserMessage = (props) => {
    return (
        <section>
            <div
                style={{
                    backgroundColor: "#a2e7a2",
                    width: "fit-content",
                    minWidth: "20%",
                    maxWidth: "60%", 
                    paddingTop: "1px",
                    paddingLeft: "15px",
                    paddingBottom: "5px", 
                    paddingRight: "10px", 
                    borderTopLeftRadius: "10px", 
                    borderBottomLeftRadius: "10px",
                    fontFamily: "Merriweather", 
                    marginTop: "15px", 
                    marginLeft: "auto", 
                    marginRight: "10px", 
                    borderTopRightRadius: "10px"
                }}>
                <p style={{ fontSize: "14px" }} key={Math.random()}>{props.myData.response_user}</p>
            </div>
        </section>
    )
}

export default UserMessage
