import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";
import axios from "../../../api/axios";

const EndButton = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    function endChat() {

        const fetchData = async () => {
            try {

                await axios.put(`/key/${localStorage.getItem("BelPaeseExpressKey")}/complete`,
                    JSON.stringify({
                        "token": localStorage.getItem('BelPaeseExpressToken'),
                        "experience_id":  parseInt(localStorage.getItem("BelPaeseExpressExperience_id"), 10),
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        },
                    }
                );

                return navigate('/review');

            } catch (err) {
                console.log(err);
                if (err.response?.status === 403) {
                    localStorage.clear();
                    return navigate('/');
                } else {
                    console.log('Errore Generico, si prega di riprovare più tardi');
                }
            }
        };
        fetchData().catch(console.error);
    };

    return (
        <div className="sticky" style={{ margin: "auto", marginTop: "350px", fontFamily: "Merriweather" }}>
            <button style={{
                position: "absolute", marginTop: "15px", padding: "10px", width: "90%", fontSize: "22px", height: "58px", color: "black",
                marginLeft: "15px", backgroundColor: "white", border: "1px solid #FDB933", borderRadius: "5px", paddingBottom: "25px", fontFamily: "Merriweather",
            }}
                onClick={endChat}>
                <p style={{ fontWeight: "bold", fontSize: "20px", marginTop: "5px" }}>{t('EndButton')}</p>
            </button>
        </div>
    )
}

export default EndButton