import React from 'react'
import $ from 'jquery';
import { putMoreOpen } from "../../api/requests";
import { useTranslation } from 'react-i18next';


const MoreMessage2 = (props) => {

    const {t} = useTranslation();

    async function showMore() {
        if(!props.myData.more_open_2) {
            try {
                await putMoreOpen(props.myData.id, 2);
            } catch (err) {
                console.error(err);
            }
        }
        props.myData.more_open_2 = true;
        $(`#moreid${props.myData.more_id_2}`).css("display", "block");
    }

    return (
        <section>
            <div onClick={showMore} key={Math.random()} style={{
                backgroundColor: "#FDB933", width: "fit-content", paddingTop: "1px", paddingLeft: "15px",
                paddingBottom: "5px", paddingRight: "10px", borderRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "0px",
                fontFamily: "Merriweather", marginBottom: "5px", marginTop: "5px", marginLeft: "12px"
            }}>
                <p style={{ fontSize: "14px" }} key={Math.random()}>{t('FindText')}</p>
            </div>
            <div id={"moreid" + props.myData.more_id_2} key={Math.random()} style={{
                backgroundColor: "#FDB933", width: "60%", paddingTop: "1px", paddingLeft: "15px", marginLeft: "12px",
                paddingBottom: "5px", paddingRight: "10px", borderRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "0px",
                fontFamily: "Merriweather", marginBottom: "5px", marginTop: "5px", display: props.myData.more_open_2  ? "block" : "none"
            }}>
                <p style={{ fontSize: "14px" }} key={Math.random()}>{props.myData.more_2}</p>
            </div>
        </section>

    )
}

export default MoreMessage2