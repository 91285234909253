import React, { useEffect } from "react";
import MoreMessage from './MoreMessage';
import MoreMessage2 from './MoreMessage2';
import HelpMessage from './HelpMessage';
import UserMessage from './UserMessage';
import ImageMessage from './ImageMessage';
import AudioMessage from './AudioMessage';
import CorrectMessage from './CorrectMessage';


const BotMessage = (props) => {

    let color = props.myData.character ? "#FFF" : "#b8b8b8"

    return (
        <section style={{ marginBottom: "20px" }}>
            {props.myData.is_correct && <CorrectMessage myData={props.myData} color={"#b8b8b8"} />}
            {props.myData.has_more && props.myData.is_more_before && <MoreMessage myData={props.myData} />}
            {props.myData.has_more_2 && props.myData.is_more_before_2 && <MoreMessage2 myData={props.myData} />}
            <div key={Math.random()} style={{
                backgroundColor: "white", width: "60%", paddingTop: "1px", paddingLeft: "15px", marginLeft: "12px",
                paddingBottom: "10px", paddingRight: "15px", borderRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "0px",
                fontFamily: "Merriweather"
            }}>
                {props.myData.is_img && <ImageMessage image={props.myData.img} />}
                <h3 key={Math.random()} style={{ fontSize: "17px" }}>{props.myData.character}</h3>
                <p key={Math.random()} style={{ fontSize: "14px" }}>{props.myData.message}</p>
                {props.myData.is_audio && <AudioMessage audio={props.myData.audio}/>}
            </div>
            {props.myData.has_more && !props.myData.is_more_before && <MoreMessage myData={props.myData} />}
            {props.myData.has_more_2 && !props.myData.is_more_before_2 && <MoreMessage2 myData={props.myData} />}
            {props.myData.has_help && <HelpMessage myData={props.myData} stageNum={props.stageNum} onClickButton={props.onClickButton}
                updateLastResponseUser={props.updateLastResponseUser} setIsSolutionOnClick={props.setIsSolutionOnClick} />}
            {props.myData.response_user !== "" && props.myData.response_user !== null && <UserMessage myData={props.myData} />}
        </section>
    )
}

export default BotMessage