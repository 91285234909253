import React from 'react'
import { useTranslation } from 'react-i18next';

const NextButton = (props) => {

    const {t} = useTranslation();

    return (
        <div className="sticky" style={{ margin: "auto", marginTop: "350px", fontFamily: "Merriweather" }}>
            <button style={{
                position: "absolute", marginTop: "15px", padding: "10px", width: "90%", fontSize: "22px", height: "58px", color: "black",
                marginLeft: "15px", backgroundColor: "white", border: "1px solid #FDB933", borderRadius: "5px", paddingBottom: "25px", fontFamily: "Merriweather",
            }}
                onClick={props.onClick}>
                <p style={{ fontWeight: "bold", fontSize: "20px", marginTop: "5px" }}>{t('ProceedText')}</p>
            </button>
        </div>
    )
}

export default NextButton