import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import '../node_modules/font-awesome/css/font-awesome.min.css';
import "../node_modules/flag-icons/css/flag-icons.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
      <App />
  </BrowserRouter>
// </React.StrictMode>
);

reportWebVitals();