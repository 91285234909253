import SweetAlert from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";

export const BlockedKeyPopup = ({ onConfirm }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const showAlert = () => {
    SweetAlert.fire({
      title: t('KeyBlockedTitle'),
      text: t('KeyBlockedText'),
      showCancelButton: false,
      confirmButtonText: t('KeyBlockedButton'),
      confirmButtonColor: 'rgb(253, 185, 51)',
      allowOutsideClick: false,
    }).then((result) => {
      onConfirm(false);
      return navigate('/');
    });
  };

  return showAlert();
};

