import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import translationIT from './it'
import translationEN from './en'
import translationES from './es'
import translationDE from './de'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEN
            },
            it: {
                translation: translationIT
            },
            es: {
                translation: translationES
            },
            de: {
                translation: translationDE
            }
        },
        // lng: "en",
        lng: localStorage.getItem("BelPaeseExpressLanguage") ? localStorage.getItem("BelPaeseExpressLanguage") : 'it',
        fallbackLng: localStorage.getItem("BelPaeseExpressLanguage") ? localStorage.getItem("BelPaeseExpressLanguage") : 'it',
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });


export default i18n;

