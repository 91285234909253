import Views from "./Views";
import { createContext, useState } from "react";
import { useLocation } from "react-router";
import "@fontsource/merriweather";

export const UserContext = createContext();

function App() {
  const [user, setUser] = useState({ loggedIn: localStorage.getItem("BelPaeseExpressExperience_name") ? true : false, key: '', username: '' });
  const location = useLocation();
  // if (user.loggedIn) {
  //   if(localStorage.getItem("BelPaeseExpressExperience_username") && (location.pathname === "/home" || location.pathname === "/")) {
  //     window.location.replace("/chat");
  //   } else if(location.pathname === "/") {
  //     window.location.replace("/home");
  //   }
  // }
  return ( 
    <UserContext.Provider value={{ user, setUser }}>
      <Views />
    </UserContext.Provider>
  )
}

export default App;