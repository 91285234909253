import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";
import axios from '../api/axios';


const UsedButtons = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    function choiceResponse(choice) {
        if (choice) {
            const fetchData = async () => {
                try {

                    let input_body = {
                        "experience_id": localStorage.getItem('BelPaeseExpressExperience_id')
                    }

                    const response = await axios.post(`/key/${localStorage.getItem("BelPaeseExpressKey")}/generate_new_token`,
                        JSON.stringify(input_body),
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*'
                            },
                        }
                    );

                    localStorage.setItem("BelPaeseExpressToken", response?.data?.token);
                    return navigate('/chat');

                } catch (err) {
                    console.log(err);
                    console.log(err.response?.status)
                    console.log('Errore Generico, si prega di riprovare più tardi');
                }
            };
            fetchData().catch(console.error);
        } else {
            localStorage.clear();
            return navigate('/');
        }
    };

    return (
        <div className="sticky" style={{ margin: "auto", marginTop: "350px", fontFamily: "Merriweather" }}>
            <button style={{
                marginTop: "15px", padding: "10px", width: "30%", fontSize: "22px", height: "58px", display: "inline-block", color: "black",
                backgroundColor: "white", border: "1px solid #FDB933", borderRadius: "5px", paddingBottom: "25px", fontFamily: "Merriweather",
            }}
                onClick={() => choiceResponse(true)}>
                <p style={{ fontWeight: "bold", fontSize: "20px", marginTop: "5px" }}>{t('Yes')}</p>
            </button>
            <button style={{
                marginTop: "15px", padding: "10px", width: "30%", fontSize: "22px", height: "58px", display: "inline-block", color: "black",
                marginLeft: "15px", backgroundColor: "white", border: "1px solid #FDB933", borderRadius: "5px", paddingBottom: "25px", fontFamily: "Merriweather",
            }}
                onClick={() => choiceResponse(false)}>
                <p style={{ fontWeight: "bold", fontSize: "20px", marginTop: "5px" }}>{t('No')}</p>
            </button>
        </div>
    )
}

export default UsedButtons