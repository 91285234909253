import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { putResponseUser } from "../../../api/requests";


const ChoiceButton = (props) => {

    const { t } = useTranslation();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    async function choiceResponse(choice) {

        if (!buttonDisabled) {

            setButtonDisabled(!buttonDisabled);

            if (choice) {
                props.myData.response_user = t('Yes');
            } else {
                props.myData.response_user = t('No');
                props.myData.stage_num = props.myData.skip_to;
            }

            const user_response = choice ? t('Yes') : t('No');
            try {
                await putResponseUser(props.myData.id, user_response);
                props.onClick();
            } finally {
                setButtonDisabled(false);
            }

        }
    };

    return (
        <div className="sticky" style={{ margin: "auto", marginTop: "350px", fontFamily: "Merriweather" }}>
            <button style={{
                marginTop: "15px", padding: "10px", width: "30%", fontSize: "22px", height: "58px", display: "inline-block", color: "black",
                marginLeft: "18%", backgroundColor: "white", border: "1px solid #FDB933", borderRadius: "5px", paddingBottom: "25px", fontFamily: "Merriweather",
            }}
                onClick={() => choiceResponse(true)}>
                <p style={{ fontWeight: "bold", fontSize: "20px", marginTop: "5px" }}>{t('Yes')}</p>
            </button>
            <button style={{
                marginTop: "15px", padding: "10px", width: "30%", fontSize: "22px", height: "58px", display: "inline-block", color: "black",
                marginLeft: "15px", backgroundColor: "white", border: "1px solid #FDB933", borderRadius: "5px", paddingBottom: "25px", fontFamily: "Merriweather",
            }}
                onClick={() => choiceResponse(false)}>
                <p style={{ fontWeight: "bold", fontSize: "20px", marginTop: "5px" }}>{t('No')}</p>
            </button>
        </div>
    )
}

export default ChoiceButton