
import axios from '../api/axios';
import React, { useContext, useRef, useState, useEffect } from "react";
import { UserContext } from "../../App";
import { useNavigate } from "react-router";
import SweetAlert from 'sweetalert2';

import i18n from '../translation/i18n';
import { useTranslation } from 'react-i18next';
import LanguageKeyAuth from './LanguageKeyAuth';

const KeyAuth = () => {

    const { t } = useTranslation();
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const userRef = useRef();
    const errRef = useRef();

    const [key, setkey] = useState('');
    const [errMsg, setErrMsg] = useState('');

    if (!localStorage.getItem("BelPaeseExpressLanguage")) {
        localStorage.setItem("BelPaeseExpressLanguage", 'it');
    }

    const [experienceName, setExperienceName] = useState('');
    const [experienceDescription, setExperienceDescription] = useState('');
    let [experienceLanguage, setExperienceLanguage] = useState(localStorage.getItem("BelPaeseExpressLanguage"));
    localStorage.setItem("BelPaeseExpressLanguage", experienceLanguage);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user])

    function reindexLang(lang) {
        setExperienceLanguage(lang);
        i18n.changeLanguage(lang);
    }

    function showAlert() {
        SweetAlert.fire({
            title: t('HelpAlertTitle'),
            text: t('HelpAlertText'),
            confirmButtonText: t('AlertButton'),
            confirmButtonColor: 'rgb(253, 185, 51)'
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            var input_body = {
                "token": localStorage.getItem("BelPaeseExpressToken"),
                "language": localStorage.getItem("BelPaeseExpressLanguage"),
            }

            const response = await axios.post(`/key/${key}/validation`,
                JSON.stringify(input_body),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );

            setUser({ loggedIn: true, key: key, username: response?.data?.username });

            localStorage.setItem("BelPaeseExpressKey", key);
            localStorage.setItem("BelPaeseExpressToken", response?.data?.token);
            localStorage.setItem("BelPaeseExpressExperience_username", response?.data?.username);

            if (JSON.stringify(response?.data?.experiences_number) == 0) {
                let error = new Error('Nessuna esperienza trovata, si prega di contattare l\'assistenza.');
                error.code = 512;
                // TODO: FIXARE MSG ERROE
                throw error;
            }

            if(response.data.language != localStorage.getItem("BelPaeseExpressLanguage")) {
                return navigate('/no-language');
            }

            if (response?.status === 200 && JSON.stringify(response?.data?.experiences_number) == 1) {

                localStorage.setItem("BelPaeseExpressExperience_id", response?.data?.experiences[0].experience_id);
                localStorage.setItem("BelPaeseExpressExperience_name", response?.data?.experiences[0].experience_name);
                localStorage.setItem("BelPaeseExpressExperience_description", response?.data?.experiences[0].experience_description);
                localStorage.setItem("BelPaeseExpressExperience_map", response?.data?.experiences[0].experience_map);

                setExperienceName(response?.data?.experience_name);
                setExperienceDescription(response?.data?.experience_description);

                return navigate('/home', { state: { name: experienceName, description: experienceDescription } });
            }
            else if (response?.status === 200 && JSON.stringify(response?.data?.experiences_number) > 1) {
                return navigate('/multi-experiences', { state: { experiences: response?.data?.experiences } });
            } else {
                reindexLang(response?.data?.language);
                localStorage.setItem("BelPaeseExpressLanguage", response?.data?.language);
                localStorage.setItem("BelPaeseExpressExperience_id", response?.data?.experiences[0].experience_id);
                localStorage.setItem("BelPaeseExpressExperience_name", response?.data?.experiences[0].experience_name);
                localStorage.setItem("BelPaeseExpressExperience_description", response?.data?.experiences[0].experience_description);
                localStorage.setItem("BelPaeseExpressExperience_map", response?.data?.experiences[0].experience_map);
                if (response?.status === 202) {
                    localStorage.setItem("BelPaeseExpressExperience_id", response?.data?.experiences[0].experience_id);
                    return navigate('/chat');
                }
                else if (response?.status === 203) {
                    localStorage.setItem("BelPaeseExpressExperience_id", response?.data?.experiences[0].experience_id);
                    return navigate('/used');
                }
            }
            
            

        } catch (err) {
            if (err.response?.status === 500) {
                setErrMsg('Errore del Server, si prega di riprovare più tardi');
            } else if (err.response?.status === 400) {
                setErrMsg('Chiave Non Valida');
            } else if (err.response?.status === 404) {
                setErrMsg('Chiave Non Trovata');
            } else if (err.code === 512) {
                setErrMsg(err.message);
            } else if (err.response?.status === 405) {
                setErrMsg(t('KeyBlockedText'));
            } else {
                setErrMsg('Errore Generico, si prega di riprovare più tardi');
            }
            errRef.current.focus();
        }
    }

    return (
        <>
            <section>
                <div style={{ margin: 'auto', textAlign: 'center', fontFamily: 'Merriweather', boxShadow: "0px 0px 0px rgb(0 0 0 / 0.2)", width: "90%", padding: "10px", backgroundColor: "#7C112B", marginTop: "20%" }}>
                    <h1 style={{ color: '#FDB933', fontSize: '18px' }}>{t("Title")}</h1>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="key" style={{ color: 'white', fontSize: '15px' }}><b>{t('TitleCode')}: </b><br /><br /></label>
                        <input style={{
                            padding: "10px", width: "80%", border: "2px solid #FDB933", fontFamily: "Merriweather",
                            fontSize: "14px"
                        }}
                            placeholder="Es. 1022308542"
                            type="text"
                            id="key"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => {
                                setkey(e.target.value);
                            }}
                            value={key}
                            required
                        />
                        <br />
                        <p ref={errRef} style={{ color: "white" }} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                        <br />
                        <button style={{
                            padding: "12px", paddingRight: "25px", paddingLeft: "25px", borderRadius: "0px", border: "2px solid #FDB933",
                            backgroundColor: "#FDB933", color: "#7C112B", fontFamily: "Merriweather"
                        }}
                        >{t('Button')}</button>
                        <br />
                        <br />
                        <br />
                        <p style={{ color: "white", fontSize: "12px" }}><b>{t('CallToActionLang')}</b></p>
                        <LanguageKeyAuth reindexLang={reindexLang} experienceLanguage={experienceLanguage} />
                        <br />
                        <p style={{ color: "white", fontSize: "12px" }}><b>{t('TextUnderBotton')}</b> <a style={{ color: "white" }} href='https://belpaeseexpress.it/missioni/'>{t('BuyLink')}</a></p>
                        <button onClick={() => showAlert()} style={{ color: "white", fontSize: "12px", backgroundColor: 'rgba(0,0,0,0)', fontFamily: "Merriweather", border: 'none' }} type="button">{t('Help')}</button>
                        <br />
                        <br />
                        <a style={{ color: "white", fontSize: "10px" }} href='https://belpaeseexpressbucket.s3.eu-central-1.amazonaws.com/generali/copyright_levitas.pdf' target="_blank">Copyright</a>
                        <br />
                    </form>
                    <br />
                    <br />
                </div>
            </section>
        </>
    )
}

export default KeyAuth


