import axios from './api/axios';
import React, { useContext, useRef, useState, useEffect } from "react";
import { UserContext } from "../App";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import SweetAlert from 'sweetalert2';

import i18n from './translation/i18n';
import LanguageKeyAuth from './keyauth/LanguageKeyAuth';

const Home = (props) => {

    const { t } = useTranslation();
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const userRef = useRef();
    const errRef = useRef();
    const [token, setToken] = useState();

    useEffect(() => {
        const token = localStorage.getItem('BelPaeseExpressToken');
        if (token) {
            setToken(token);
        }
    }, []);

    function reindexLang(lang) {
        setExperienceLanguage(lang);
        i18n.changeLanguage(lang);
    }

    const [name, setName] = useState('');
    const [errMsg, setErrMsg] = useState('');
    let [experienceLanguage, setExperienceLanguage] = useState(localStorage.getItem("BelPaeseExpressLanguage"));
    localStorage.setItem("BelPaeseExpressLanguage", experienceLanguage);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user])

    function showAlert() {
        SweetAlert.fire({
            title: t('HelpAlertTitle'),
            text: t('HelpAlertText'),
            confirmButtonText: t('AlertButton'),
            confirmButtonColor: 'rgb(253, 185, 51)'
        })
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        try {

            try {
                const response_language = await axios.put(`/key/${localStorage.getItem("BelPaeseExpressKey")}/language`,
                JSON.stringify({
                    "token": token,
                    "experience_id": parseInt(localStorage.getItem("BelPaeseExpressExperience_id"), 10),
                    "language": experienceLanguage
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );
            } catch (err) {
                console.log('Error save language set.')
            }
            

            const response = await axios.put(`/key/${localStorage.getItem("BelPaeseExpressKey")}/name`,
                JSON.stringify({
                    "token": token,
                    "experience_id": parseInt(localStorage.getItem("BelPaeseExpressExperience_id"), 10),
                    "name": name
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );
            setUser({ loggedIn: true });
            
            if (response.status === 200) {
                localStorage.setItem("BelPaeseExpressExperience_username", name);
                return navigate('/chat');
            }

        } catch (err) {
            if (!err?.response || err.response?.status === 500) {
                setErrMsg('Internal Server error');
            } else if (err.response?.status === 400) {
                setErrMsg('License Not Valid');
            } else if (err.response?.status === 404) {
                setErrMsg('License Not Found');
            } else if (err.response?.status === 405) {
                setErrMsg(t('KeyBlockedText'));
            } else {
                setErrMsg('An error is occured');
            }
            errRef.current.focus();
        }
    }

    return (
        <section>
            <div style={{ margin: 'auto', textAlign: 'center', fontFamily: 'Merriweather', boxShadow: "0px 0px 0px rgb(0 0 0 / 0.2)", width: "90%", padding: "10px", backgroundColor: "#7C112B", marginTop: "15%" }}>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <h1 style={{ color: '#FDB933' }}>{localStorage.getItem("BelPaeseExpressExperience_name")}</h1>
                <p style={{ color: 'white' }}>{localStorage.getItem("BelPaeseExpressExperience_description")}</p>
                <br /><br />
                <form onSubmit={handleSubmit}>
                    <label htmlFor="name" style={{ color: 'white' }}><b>{t('TitleName')}</b><br /><br /></label>
                    <input style={{
                        padding: "10px", width: "80%", border: "2px solid #FDB933", fontFamily: "Merriweather",
                        fontSize: "14px"
                    }}
                        placeholder="Andrea"
                        type="text"
                        id="name"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                    />
                    <br />
                    <p ref={errRef} style={{ color: "white" }} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <br />
                    <button type='submit' style={{
                        padding: "15px", paddingRight: "25px", paddingLeft: "25px", borderRadius: "0px", border: "2px solid #FDB933",
                        backgroundColor: "#FDB933", color: "#7C112B", fontFamily: "Merriweather", fontSize: '12px'
                    }}>{t('Button')}</button>
                </form>
                <br />
                <br />
                <p style={{ color: "white", fontSize: "12px" }}><b>{t('TextUnderBotton')}</b> <a style={{ color: "white" }} href='https://belpaeseexpress.it/missioni/'>{t('BuyLink')}</a></p>
                <button onClick={() => showAlert()} style={{ color: "white", fontSize: "12px", backgroundColor: 'rgba(0,0,0,0)', fontFamily: "Merriweather", border: 'none' }} type="button">{t('Help')}</button>
                <br />
                <br />
                <p style={{ color: "white", fontSize: "12px" }}><b>{t('CallToActionLang')}</b></p>
                <LanguageKeyAuth reindexLang={reindexLang} experienceLanguage={experienceLanguage} />
                <br />
                <a style={{ color: "white", fontSize: "10px" }} href='https://belpaeseexpressbucket.s3.eu-central-1.amazonaws.com/generali/copyright_levitas.pdf' target="_blank">Copyright</a>
                <br />
            </div>
        </section>
    )
}

export default Home;
