import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { useTranslation} from 'react-i18next';

const InputText = (props) => {
    const {t} = useTranslation();

    const [responseUser, setResponseUser] = useState("");

    return (
        <div className="sticky" style={{ margin: "auto", marginTop: "350px" }}>                    
            <FontAwesomeIcon style={{ position: "absolute", color: "black", marginTop: "35px", fontSize: "20px", marginLeft: "25px" }} icon={faPen} />
            <input type="text" placeholder={t('PlaceHolderChat')}
                style={{ width: "66vw", padding: "20px", paddingLeft:"40px", borderRadius: "5px", border: "1px solid #FDB933", marginTop: "15px", fontFamily: "FontAwesome, Merriweather", marginLeft: "10px", fontSize: "15px" }}
                value={responseUser}
                onChange={(e) => {
                    setResponseUser(e.target.value);
                    props.setResponseUser(e.target.value);
                }}
            />
            <button style={{
                position: "absolute", marginTop: "15px", padding: "10px", width: "14vw", fontSize: "22px", height: "58px",
                marginLeft: "5px", backgroundColor: "white", border: "1px solid #FDB933", borderRadius: "5px", paddingBottom: "25px",
            }}
                onClick={() => {
                    if(responseUser != ""){
                        setResponseUser("");
                        props.onClick();
                    }
                }}>
                <FontAwesomeIcon style={{ color: "black", marginTop: "8px" }} icon={faPaperPlane} />
            </button>
        </div>
    )
}

export default InputText