import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const LanguageButton = (props) => {
    return (
        <button onClick={() => props.reindexLang(props.language)} style={{
            display: 'inline-block', marginLeft: '10px', fontFamily: 'Merriweather', border: 'none',
            backgroundColor: 'rgb(255 255 255 / 0%)', color: 'white', fontSize: '16px', textDecoration: props.experienceLanguage === props.language ? 'underline' : 'none',
        }} type='button'><span class={props.icon}></span></button>
    )
};

const LanguageKeyAuth = (props) => {

    return (
        <Container style={{ color: 'white', fontFamily: 'Merriweather' }}>
            <Row style={{ display: 'inline-block' }}>                
                <LanguageButton language={'it'} icon={'fi fi-it'} reindexLang={props.reindexLang} experienceLanguage={props.experienceLanguage} />
                <LanguageButton language={'en'} icon={'fi fi-gb'} reindexLang={props.reindexLang} experienceLanguage={props.experienceLanguage} />
                <LanguageButton language={'es'} icon={'fi fi-es'} reindexLang={props.reindexLang} experienceLanguage={props.experienceLanguage} />
                <LanguageButton language={'de'} icon={'fi fi-de'} reindexLang={props.reindexLang} experienceLanguage={props.experienceLanguage} />
            </Row>
        </Container>
    )
}
export default LanguageKeyAuth;