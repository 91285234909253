import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { putResponseUser } from "../../../api/requests";

const DoneButton = (props) => {

    const { t } = useTranslation();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    async function doneResponse() {

        if (!buttonDisabled) {

            setButtonDisabled(true);

            try {
                props.myData.response_user = t('DoneText');
                await putResponseUser(props.myData.id, t('DoneText'));
                props.onClick();
            } finally {
                setButtonDisabled(false);
            }

        }
    };

    return (
        <div className="sticky" style={{ margin: "auto", marginTop: "350px", fontFamily: "Merriweather" }}>
            <button style={{
                position: "absolute", marginTop: "15px", padding: "10px", width: "90%", fontSize: "22px", height: "58px", color: "black",
                marginLeft: "15px", backgroundColor: "white", border: "1px solid #FDB933", borderRadius: "5px", paddingBottom: "25px", fontFamily: "Merriweather",
            }}
                onClick={doneResponse}>
                <p style={{ fontWeight: "bold", fontSize: "20px", marginTop: "5px" }}>{t('DoneText')}</p>
            </button>
        </div>
    )
}

export default DoneButton