import axios from './axios';

export const fetchLogMessages = async () => {
    try {
        const listLogMessage = await axios.post(`/log/${localStorage.getItem("BelPaeseExpressKey")}/messages`,
            JSON.stringify({
                "experience_id": localStorage.getItem("BelPaeseExpressExperience_id")
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            }
        );

        return { response: listLogMessage, error: false, navigate: null };

    } catch (err) {
        if (err.response?.status === 403) {
            return { response: null, error: true, navigate: '/' };
        } else {
            console.log('Errore Generico, si prega di riprovare più tardi');
            return { response: null, error: true, navigate: null };
        }
    }
};

export const fetchStartExperience = async () => {
    try {
        const response = await axios.post(`/stage/${localStorage.getItem("BelPaeseExpressKey")}/start`,
            JSON.stringify({
                "token": localStorage.getItem('BelPaeseExpressToken'),
                "language": localStorage.getItem("BelPaeseExpressLanguage"),
                "experience_id": localStorage.getItem("BelPaeseExpressExperience_id")
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            }
        );

        return { response: response, error: false, connection: false };

    } catch (err) {
        if (err?.code === 'ERR_NETWORK') {
            return { response: err, error: true, connection: true };
        } else {
            console.log('Errore Generico, si prega di riprovare più tardi');
            return { response: err, error: true, connection: false };
        }
    }
};

export const fetchNextStage = async (next_stage) => {
    try {
        const response = await axios.post(`/stage/${localStorage.getItem("BelPaeseExpressKey")}/next/${next_stage}`,
            JSON.stringify({
                "token": localStorage.getItem('BelPaeseExpressToken'),
                "language": localStorage.getItem("BelPaeseExpressLanguage"),
                "experience_id": localStorage.getItem("BelPaeseExpressExperience_id")
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            }
        );

        return { response: response, error: false, connection: false };

    } catch (err) {
        if (err?.code === 'ERR_NETWORK') {
            return { response: err, error: true, connection: true };
        } else {
            console.log('Errore Generico, si prega di riprovare più tardi');
            return { response: err, error: true, connection: false };
        }
    }
};

export const fetchCheckResponse = async (response_id, stage_num, response_user, is_solution_on_click, try_num) => {
    try {
        const response = await axios.post(`/response/${localStorage.getItem("BelPaeseExpressKey")}/${response_id}/check`,
            JSON.stringify({
                "token": localStorage.getItem('BelPaeseExpressToken'),
                "experience_id": localStorage.getItem("BelPaeseExpressExperience_id"),
                "stage_num": stage_num,
                "language": localStorage.getItem("BelPaeseExpressLanguage"),
                "user_response": response_user,
                "is_solution_on_click": is_solution_on_click,
                "try_num": try_num
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            }
        );

        return { response: response, error: false, connection: false };

    } catch (err) {
        if (err?.code === 'ERR_NETWORK') {
            return { response: err, error: true, connection: true };
        } else {
            console.log('Errore Generico, si prega di riprovare più tardi');
            return { response: err, error: true, connection: false };
        }
    }
};

export const putResponseUser = async (log_id, user_response) => {
    try {

        await axios.put(`/log/${localStorage.getItem("BelPaeseExpressKey")}/update_response_user_log/${log_id}`,
            JSON.stringify({
                "token": localStorage.getItem('BelPaeseExpressToken'),
                "experience_id": localStorage.getItem('BelPaeseExpressExperience_id'),
                "user_response": user_response,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            }
        );

        return { error: false, connection: false };

    } catch (err) {
        if (err?.code === 'ERR_NETWORK') {
            return { response: err, error: true, connection: true };
        } else {
            console.log('Errore Generico, si prega di riprovare più tardi');
            return { response: err, error: true, connection: false };
        }
    }
};

export const putHelpNumber = async (log_id, help_num) => {
    try {

        await axios.put(`/log/${localStorage.getItem("BelPaeseExpressKey")}/update_help_num_log/${log_id}`,
            JSON.stringify({
                "token": localStorage.getItem('BelPaeseExpressToken'),
                "experience_id": localStorage.getItem('BelPaeseExpressExperience_id'),
                "help_num": help_num,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            }
        );

        return { error: false, connection: false };

    } catch (err) {
        if (err?.code === 'ERR_NETWORK') {
            return { response: err, error: true, connection: true };
        } else {
            console.log('Errore Generico, si prega di riprovare più tardi');
            return { response: err, error: true, connection: false };
        }
    }
};

export const putMoreOpen = async (log_id, num) => {
    try {

        await axios.put(`/log/${localStorage.getItem("BelPaeseExpressKey")}/update_more_open_log/${log_id}`,
            JSON.stringify({
                "token": localStorage.getItem('BelPaeseExpressToken'),
                "experience_id": localStorage.getItem('BelPaeseExpressExperience_id'),
                "num": num,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            }
        );

        return { error: false, connection: false };

    } catch (err) {
        if (err?.code === 'ERR_NETWORK') {
            return { response: err, error: true, connection: true };
        } else {
            console.log('Errore Generico, si prega di riprovare più tardi');
            return { response: err, error: true, connection: false };
        }
    }
};
