import React from 'react'

const CorrectMessage = (props) => {



    return (
        <section style={{ marginBottom: "20px" }}>
            <div key={Math.random()} style={{
                backgroundColor: props.color,
                width: "fit-content",
                minWidth: "20%",
                maxWidth: "80%",
                paddingTop: "1px",
                paddingLeft: "15px",
                marginLeft: "12px",
                paddingBottom: "10px",
                paddingRight: "15px",
                borderRadius: "10px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "0px",
                fontFamily: "Merriweather",
            }}>
                <p key={Math.random()}>{props.myData.correct_response}</p>
            </div>
        </section>
    )
}

export default CorrectMessage