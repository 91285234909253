import React, { useEffect, useState, useRef } from "react";
import { fetchLogMessages, fetchStartExperience, fetchNextStage, fetchCheckResponse } from "../api/requests";
import BotMessage from "./messages/BotMessage";
import InputHub from "./inputs/InputHub";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";
import RetryPopup from "./RetryPopup";
import { showAlert } from "../alerts/ShowAlert";
import { showAlertInfo } from "../alerts/ShowAlertInfo";
import { showAlertMap } from "../alerts/ShowAlertMap";
import { BlockedKeyPopup } from "../alerts/BlockedKeyPopup";

const useMountEffect = fun => useEffect(fun, []);

const Chat = (props) => {

    const { t } = useTranslation();
    const [responseUser, setResponseUser] = useState("");

    const updateLastResponseUser = (resp) => {
        data[data.length - 1].response_user = resp
    };

    const setIsSolutionOnClick = (value) => {
        data[data.length - 1].is_solution_on_click = value
    };

    const [data, setData] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [stage, setStage] = useState(1);
    const [stage_num_react, setStageNumReact] = useState(1);
    const [showOpenRetryPopup, setshowOpenRetryPopup] = useState(false);
    const [showOnClickButtonRetryPopup, setshowOnClickButtonRetryPopup] = useState(false);
    const [showOnClickButtonBlockedKeyPopup, setShowOnClickButtonBlockedKeyPopup] = useState(false);
    const navigate = useNavigate();

    const setMessage = (stage, msg) => {
        setStageNumReact((prev_stage_num) => msg['stage_num']);

        return {
            "id": stage,
            "stage_num": msg['stage_num'],
            "character": msg['character'],
            "message": msg['message'],
            "is_request": msg['is_request'],
            "is_yes_no": msg['is_yes_no'],
            "is_choice": msg['is_choice'],
            "is_img": msg['is_img'],
            "is_audio": msg['is_audio'],
            "is_error": msg['is_error'],
            "is_last": msg['is_last'],
            "has_response": msg['has_response'],
            "has_help": msg['has_help'],
            "has_more": msg['has_more'],
            "has_more_2": msg['has_more_2'],
            "skip_to": msg['skip_to'],
            "response_id": msg['response_id'],
            "help_id": msg['help_id'],
            "more_id": msg['more_id'],
            "more_id_2": msg['more_id_2'],
            "is_more_before": msg['is_more_before'],
            "is_more_before_2": msg['is_more_before_2'],
            "response": msg['response'],
            "help": msg['help'],
            "more": msg['more'],
            "more_2": msg['more_2'],
            "img": msg['img'],
            "audio": msg['audio'],
            "response_user": msg['response_user'],
            "try_num": msg['try_num'],
            "help_num": msg['help_num'],
            "more_open": msg['more_open'],
            "more_open_2": msg['more_open_2'],
            "is_correct": msg['is_correct'],
            "correct_response": msg['correct_response'],
            "is_solution_on_click": false,
        }
    };

    const setDataHandle = (msg) => {
        setData([
            ...data,
            setMessage(stage, msg)
        ])
    };

    const retryOpenRequest = async () => {
        setshowOpenRetryPopup(false);
        await fetchFirstOpenData();
    };

    const retryOnClickButtonRequest = async () => {
        setshowOnClickButtonRetryPopup(false);
        await fetchOnClickButtonData();
        if (showOnClickButtonRetryPopup) {
            setStage((prevState) => prevState - 1);
        }
    };

    const fetchFirstOpenData = async () => {

        try {
            const { response: listLogMessage, error, navigate } = await fetchLogMessages();

            if (error) {
                if (navigate) {
                    localStorage.clear();
                    return navigate(navigate);
                } else {
                    setshowOpenRetryPopup(true);
                }
                return;
            }

            if (Object.keys(listLogMessage.data).length === 0) {
                setStage((prevState) => prevState + 1);

                const { response: response, error, connection } = await fetchStartExperience();

                if (error) {
                    if (connection) {
                        setshowOpenRetryPopup(true);
                    } else {
                        throw response;
                    }
                    return;
                }

                const msg = response.data;
                setDataHandle(msg);
            } else {
                const logMessageArray = []
                for (var elem in listLogMessage.data) {
                    setStage((prevState) => prevState + 1);
                    const msg = listLogMessage.data[elem];
                    logMessageArray.push(setMessage(msg['id_user'], msg));
                }
                setData(logMessageArray);
            }
        } catch (err) {
            console.log('Errore Generico, si prega di riprovare più tardi');
            if (err.response?.status === 405) {
                setShowOnClickButtonBlockedKeyPopup(true);
            }
        }
    }

    useEffect(() => {

        async function fetchData() {
            await fetchFirstOpenData()
                .catch(console.error);
        }

        fetchData()
            .catch(console.error);


        const onPageLoad = () => {
            executeScroll();
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    const fetchOnClickButtonData = async () => {

        let response_to_fetch = null;


        try {
            if ((!data[data.length - 1].is_request && !data[data.length - 1].is_error) || data[data.length - 1].is_yes_no || data[data.length - 1].is_choice) {

                let { response, error, connection } = await fetchNextStage(data[data.length - 1].stage_num + 1);

                if (error) {
                    if (connection) {
                        setshowOnClickButtonRetryPopup(true);
                    } else {
                        throw response;
                    }
                    return;
                }

                response_to_fetch = response;

            } else {
                let { response, error, connection } = await fetchCheckResponse(
                    data[data.length - 1].response_id,
                    data[data.length - 1].stage_num,
                    data[data.length - 1].response_user,
                    data[data.length - 1].is_solution_on_click,
                    data[data.length - 1].try_num
                );
                
                if (error) {
                    if (connection) {
                        setshowOnClickButtonRetryPopup(true);
                    } else {
                        throw response;
                    }
                    return;
                }

                response_to_fetch = response;
            }

        } catch (err) {
            console.log(err);
            if (err.response?.status === 403) {
                localStorage.clear();
                return navigate('/');
            } else if (err.response?.status === 405) {
                setShowOnClickButtonBlockedKeyPopup(true);
            } else {
                console.log('Errore Generico, si prega di riprovare più tardi');
                console.log(err?.code);
            }
        }
        const msg = response_to_fetch.data;
        setDataHandle(msg);
    };

    const onClickButton = async () => {

        if (!buttonDisabled) {
            setButtonDisabled(true);

            try {
                if (data[data.length - 1].response_user == null) {
                    data[data.length - 1].response_user = responseUser;
                }

                setStage((prevState) => prevState + 1);
                setResponseUser("");

                executeScroll();

                await fetchOnClickButtonData();
            } catch (error) {
                if (error.response?.status === 405) {
                    setShowOnClickButtonBlockedKeyPopup(true);
                }
            } finally {
                setButtonDisabled(false);
            }
        }
    };

    const myRef = useRef(null);

    const executeScroll = () => myRef.current.scrollIntoView();

    useMountEffect(executeScroll);

    return (
        <section>
            {showOpenRetryPopup && <RetryPopup onRetry={retryOpenRequest} />}
            {showOnClickButtonBlockedKeyPopup && <BlockedKeyPopup onConfirm={setShowOnClickButtonBlockedKeyPopup} />}
            {!showOpenRetryPopup && showOnClickButtonRetryPopup && <RetryPopup onRetry={retryOnClickButtonRequest} />}
            <div style={{ position: "sticky", top: 83, paddingBottom: '5px', textAlign: "center", color: "white", fontFamily: "Merriweather", backgroundColor: "rgb(124 17 43 / 100%)" }}>
                <div style={{ height: "10px" }}></div>
                <h2 style={{ backgroundColor: "rgb(124 17 43 / 100%)", marginTop: "0px", fontSize: "20px" }}>
                    <button style={{ marginLeft: "0px", backgroundColor: "rgba(0,0,0,0)", border: "none", marginRight: "-10px" }} onClick={() => showAlertMap(t)}><FontAwesomeIcon style={{ color: "white", fontSize: "25px", marginRight: "12px" }} icon={faMapLocationDot} /></button>
                    {localStorage.getItem('BelPaeseExpressExperience_name')}
                    <button style={{ marginLeft: "0px", backgroundColor: "rgba(0,0,0,0)", border: "none" }} onClick={() => showAlert(t)}><FontAwesomeIcon style={{ color: "rgb(253, 185, 51)", fontSize: "20px" }} icon={faTriangleExclamation} /></button>
                    <button style={{ marginLeft: "0px", backgroundColor: "rgba(0,0,0,0)", border: "none" }} onClick={() => showAlertInfo(t)}><FontAwesomeIcon style={{ color: "white", fontSize: "20px" }} icon={faInfoCircle} /></button>
                </h2>
            </div>
            <div>
                {data.map((myData) => (
                    <BotMessage myData={myData} stageNum={stage_num_react} onClickButton={onClickButton} updateLastResponseUser={updateLastResponseUser} setIsSolutionOnClick={setIsSolutionOnClick}/>
                ))}
                <div ref={myRef} />
                {
                    <InputHub data={data} onClickButton={onClickButton} setResponseUser={setResponseUser} />
                }
            </div>
        </section>
    )
}

export default Chat;