import axios from '../api/axios';
import React, { useContext, useRef, useState, useEffect } from "react";
import { UserContext } from "../../App";
import { useNavigate } from "react-router";
import $ from 'jquery';
import i18n from '../translation/i18n';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

export const Review = () => {
  const { t } = useTranslation();
  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_iivknq7', 'template_wr73kzx', form.current, 'T8JPw8PVNtZ-pof6K')
      .then((result) => {
        returnHome();
      }, (error) => {
      });

    const fetchData = async () => {
      try {

        await axios.put(`/key/${localStorage.getItem("BelPaeseExpressKey")}/review`,
          JSON.stringify({
            "token": localStorage.getItem('BelPaeseExpressToken'),
            "review": 1,
            "experience_id": localStorage.getItem('BelPaeseExpressExperience_id')
          }),
          {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
          }
        );

      } catch (err) {
        console.log(err);
        if (err.response?.status === 403) {
          localStorage.clear();
          return navigate('/');
        } else {
          console.log('Errore Generico, si prega di riprovare più tardi');
        }
      }

    };

    fetchData().catch(console.error);
  };

  function returnHome() {
    localStorage.clear();
    return navigate('/');
  }

  function setAge(age) {
    if ($('#first_question').val().includes(age)) {
      $('#first_question').val($('#first_question').val().replace(age, ''));
    } else {
      $('#first_question').val($('#first_question').val() + " " + age);
    }
  }

  function setType(type) {
    if ($('#second_question').val().includes(type)) {
      $('#second_question').val($('#second_question').val().replace(type, ''));
      if (type == "Altro") {
        $('#other_answer').val('');
        $('#other_answer').css('display', 'none');
      }
    } else {
      $('#second_question').val($('#second_question').val() + " " + type);
      if (type == "Altro") {
        $('#other_answer').css('display', 'block');
      } else if (!$('#second_question').val().includes('Altro')) {
        $('#other_answer').val('');
        $('#other_answer').css('display', 'none');
      }
    }
  }

  function setVote(vote) {
    for(let i = 1; i < 6; i++) {
      if(i != vote) {
        $('#vote_'+i).prop('checked', false);
      }
    }
    $('#vote').val('');
    $('#vote').val(vote);
  }

  return (
    <section>
      <div style={{ margin: 'auto', textAlign: 'center', fontFamily: 'Merriweather', boxShadow: "0px 0px 0px rgb(0 0 0 / 0.2)", width: "90%", padding: "10px", backgroundColor: "#7C112B" }}>
        <h1 style={{ color: '#FDB933', fontSize: '18px', display: 'none' }}>{t('ReviewTitle')}</h1>
        <h3 style={{ color: 'white', fontSize: '16px' }}>{t('ReviewDesc')}</h3>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="experience_name" value={localStorage.getItem("BelPaeseExpressExperience_name")} style={{ display: "none" }} />
          <input type="text" name="username" value={localStorage.getItem("BelPaeseExpressExperience_username")} style={{ display: "none" }} />
          <label style={{ color: 'white', fontSize: "15px" }}>{t('Review1')}<br></br></label>
          <div style={{ display: "none" }}>
            <input onChange={() => setAge('>18')} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>{t('Answer11')}</p>
          </div>
          <div style={{ display: "inline-block" }}>
            <input onChange={() => setAge('<20')} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>{t('Answer12')}</p>
          </div>
          <div style={{ display: "inline-block" }}>
            <input onChange={() => setAge('20-40')} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>{t('Answer13')}</p>
          </div>
          <div style={{ display: "inline-block" }}>
            <input onChange={() => setAge('41-60')} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>{t('Answer14')}</p>
          </div>
          <div style={{ display: "inline-block" }}>
            <input onChange={() => setAge('>60')} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px", marginTop: "-10px" }}>{t('Answer15')}</p>
          </div>
          <input style={{ display: "none" }} type="text" name="first_question" id='first_question' />
          <br />
          <label style={{ color: 'white', fontSize: "15px" }}>{t('Review2')}<br></br></label>
          <div style={{ display: "inline-block" }}>
            <input onChange={() => setType('Social')} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>{t('Answer21')}</p>
          </div>          
          <div style={{ display: "inline-block" }}>
            <input onChange={() => setType('Web')} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>{t('Answer22')}</p>
          </div>
          <div style={{ display: "inline-block" }}>
            <input onChange={() => setType('Volantini')} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>{t('Answer23')}</p>
          </div>
          <div style={{ display: "inline-block" }}>
            <input onChange={() => setType('Passaparola')} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px", marginTop: "-10px" }}>{t('Answer24')}</p>
          </div>
          <div style={{ display: "inline-block" }}>
            <input onChange={() => setType('Altro')} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px", marginTop: "-10px" }}>{t('Answer25')}</p>
          </div>
          <input type="text" name='other_answer' id='other_answer' style={{ display: "none", width: "95%", height: "30px" }} />
          <input style={{ display: "none" }} type="text" name="second_question" id='second_question' />
          <br />
          <br />
          <label style={{ color: 'white', fontSize: "15px" }}>{t('Review3')}<br></br></label>
          <div style={{ display: "inline-block" }}>
            <input id='vote_1' onChange={() => setVote(1)} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>1</p>
            <input id='vote_2' onChange={() => setVote(2)} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>2</p>
            <input id='vote_3' onChange={() => setVote(3)} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>3</p>
            <input id='vote_4' onChange={() => setVote(4)} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>4</p>
            <input id='vote_5' onChange={() => setVote(5)} style={{ width: "15px", height: "15px" }} type="checkbox" /><p style={{ color: "white", display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>5</p>
          </div>
          <textarea type="text" name='vote' id='vote' style={{ width: "95%", height: "15px", padding: "10px", fontFamily: "Merriweather", display: "none" }} placeholder={t('Answer31')}/>          
          <textarea type="text" name='message' id='message' style={{ width: "95%", height: "72px", padding: "10px", fontFamily: "Merriweather" }} placeholder={t('Answer32')} required/>          
          <br /><br />
          <input type="submit" value={t('ReviewButton')} style={{
            padding: "10px", paddingRight: "25px", paddingLeft: "25px", borderRadius: "0px", border: "2px solid #FDB933",
            backgroundColor: "#FDB933", color: "#7C112B", fontFamily: "Merriweather", fontSize: "13px"
          }} />
          <br />
          <br />
          <p style={{ color: "white", fontSize: "12px" }}>{t('ReviewFinalText')}</p>
        </form>
      </div>
    </section>
  );
};

export default Review;
