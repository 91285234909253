import React from 'react'
import { useTranslation } from 'react-i18next';

const ResponseMessage = (props) => {

    const { t } = useTranslation();

    function getResponse() {
        if (props.stageNum === props.myData.stage_num) {
            props.setIsSolutionOnClick(true);
            props.updateLastResponseUser(props.myData.response);
            props.onClickButton();
        }
    };

    return (
        <section>
            <div onClick={getResponse} key={Math.random()} style={{
                backgroundColor: "rgb(88 133 215)", width: "fit-content",
                minWidth: "20%",
                maxWidth: "40%", paddingTop: "1px", paddingLeft: "15px",
                paddingBottom: "5px", paddingRight: "10px", borderRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "0px",
                fontFamily: "Merriweather", marginTop: "5px", marginLeft: "12px"
            }}>
                <p key={Math.random()} style={{ textTransform: "capitalize", fontSize: "14px" }}>{t('SolutionText')}</p>
            </div>
        </section>

    )
}

export default ResponseMessage