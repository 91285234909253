import SweetAlert from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const RetryPopup = ({ onRetry }) => {
  const { t } = useTranslation();

  const showAlert = () => {
    SweetAlert.fire({
      title: t('RetryTitle'),
      text: t('RetryText'),
      showCancelButton: false,
      confirmButtonText: t('RetryButton'),
      confirmButtonColor: 'rgb(253, 185, 51)',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        onRetry();
      }
    });
  };

  return showAlert();
};

export default RetryPopup;
