import React from 'react'
import $ from 'jquery';
import { putHelpNumber } from "../../api/requests";
import ResponseMessage from './ResponseMessage';

import { useTranslation } from 'react-i18next';

const HelpMessage = (props) => {

    const { t } = useTranslation();

    async function showHelp() {   

        props.myData.help_num += 1;
        if ($(`#helpid1${props.myData.help_id}`).css("display") === "none") {
            $(`#helpid1${props.myData.help_id}`).css("display", "block");
        } else if ($(`#helpid2${props.myData.help_id}`).css("display") === "none") {
            $(`#helpid2${props.myData.help_id}`).css("display", "block");
        } else if ($(`#helpid3${props.myData.help_id}`).css("display") === "none") {
            $(`#helpid3${props.myData.help_id}`).css("display", "block");
        }

        if (props.myData.help_num > 2 && props.myData.response_id != null) {
            $(`#responseid${props.myData.response_id}`).css("display", "block");
        }

        if (props.myData.help_num <= 3) {
            await putHelpNumber(props.myData.id, props.myData.help_num);
        }
    }

    return (
        <section>
            <div onClick={showHelp} key={Math.random()} style={{
                backgroundColor: "rgb(154 206 251)", 
                width: "fit-content", paddingTop: "1px", paddingLeft: "15px",
                paddingBottom: "5px", paddingRight: "10px", borderRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "0px",
                fontFamily: "Merriweather", marginTop: "5px", marginLeft: "12px"
            }}>
                <p style={{ fontSize: "14px" }} key={Math.random()}>{t('HelpText')}</p>
            </div>
            <div id={"helpid1" + props.myData.help_id} key={Math.random()} style={{
                backgroundColor: "rgb(154 206 251)", width: "60%", paddingTop: "1px", paddingLeft: "15px", marginLeft: "12px",
                paddingBottom: "5px", paddingRight: "15px", borderRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "0px",
                fontFamily: "Merriweather", marginTop: "5px", display: props.myData.help_num > 0 ? "block" : "none"
            }}>
                <p style={{ fontSize: "14px" }} key={Math.random()}>{props.myData.help[0]}</p>
            </div>
            <div id={"helpid2" + props.myData.help_id} key={Math.random()} style={{
                backgroundColor: "rgb(154 206 251)", width: "60%", paddingTop: "1px", paddingLeft: "15px", marginLeft: "12px",
                paddingBottom: "5px", paddingRight: "15px", borderRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "0px",
                fontFamily: "Merriweather", marginTop: "5px", display: props.myData.help_num > 1 ? "block" : "none"
            }}>
                <p style={{ fontSize: "14px" }} key={Math.random()}>{props.myData.help[1]}</p>
            </div>
            <div id={"helpid3" + props.myData.help_id} key={Math.random()} style={{
                backgroundColor: "rgb(154 206 251)", width: "60%", paddingTop: "1px", paddingLeft: "15px", marginLeft: "12px",
                paddingBottom: "5px", paddingRight: "15px", borderRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "0px",
                fontFamily: "Merriweather", marginTop: "5px", display: props.myData.help_num > 2 ? "block" : "none"
            }}>
                <p style={{ fontSize: "14px" }} key={Math.random()}>{props.myData.help[2]}</p>
            </div>
            <div id={"responseid" + props.myData.response_id} style={{ display: (props.myData.help_num >= 3 && props.myData.response_id != null) ? "block" : "none" }} >
                <ResponseMessage myData={props.myData} stageNum={props.stageNum}
                    onClickButton={props.onClickButton} updateLastResponseUser={props.updateLastResponseUser} setIsSolutionOnClick={props.setIsSolutionOnClick} />
            </div>
        </section>
    )
}

export default HelpMessage