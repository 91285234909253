import React from 'react'

const ImageMessage = ({ image }) => {

    return (
        <section>
            <img src={image} style={{ width: "100%", marginTop: "10px", borderRadius: "8px" }} />
        </section>
    )
}

export default ImageMessage
